//import { lazy } from 'react'
import { getClientData } from '../firebase/obj/client'
import { navigate } from '@reach/router'

//const gju = lazy(() => import('geojson-utils'))
const gju = require('geojson-utils')

export const units = [
    {
        name: 'Kilo',
        unit: 'kg'
    },
    {
        name: 'Gramo',
        unit: 'g'
    },
    {
        name: 'Unidad',
        unit: 'u'
    },
    {
        name: 'Feta',
        unit: 'feta'
    },
    {
        name: 'Metro',
        unit: 'm'
    },
]

export const days = [
    { id: 0, name: 'Domingo' },
    { id: 1, name: 'Lunes' },
    { id: 2, name: 'Martes' },
    { id: 3, name: 'Miércoles' },
    { id: 4, name: 'Jueves' },
    { id: 5, name: 'Viernes' },
    { id: 6, name: 'Sábado' }
]

export const numberToCardinals = {
    1: 'Primera',
    2: 'Segunda',
    3: 'Tercera',
    4: 'Cuarta',
    5: 'Quinta',
    6: 'Sexta',
    7: 'Séptima',
    8: 'Octava',
    9: 'Novena',
    10: 'Décima'
}


export const keyBy = (arr, key) => arr.reduce((acc, el) => {
    acc[el[key]] = el
    return acc
}, {})
//// Uso de KeyBy
/* const keyedUsers = keyBy(users, 'id')
keyedUsers[user.id] */



export const orderByDelayedFirst = (arr) => {
    try {
        const result0 = [], result1 = [], result2 = []

        for (let i = 0; i < arr.length; i++) {
            if (arr[i].orderState === 'Pendiente') {
                result0.push(arr[i])
            } else if (arr[i].orderState === 'Demorado') {
                result1.push(arr[i])
            } else {
                result2.push(arr[i])
            }
        }
        return result0.concat(result1).concat(result2)
    } catch (error) {
        console.error('orderByDelayedFirst', error)
    }
    return arr
}


export const snapshotToArray = (snapshot) =>
    snapshot.map(item => (
        {
            ...item.data(),
            id: item.id
        }
    ))

export const pointsToArray = (points = []) => {
    let res = []
    points.forEach(point => res.push([point._lat, point._long]))
    return res.length === 0 ? null : res
}

export const firebasePointsToArray = (points = []) => {
    let res = []
    points.forEach(point => res.push([point.latitude, point.longitude]))
    return res
}

//[[-32.3361964, -58.0804927], [-32.3179644, -58.0789667], [-32.3153512, -58.1006685], [-32.3272004, -58.1025877]]


export const isMarkerInsidePolygon = (marker, polygons) => {
    let _region = ""
    let _polygons = []

    polygons.forEach(p => {
        const pol = {
            "type": "Polygon",
            "coordinates": [firebasePointsToArray(p.positions)],
            "name": p.name
        }
        _polygons.push(pol)
    })

    _polygons.forEach(polygon => {
        const result = gju.pointInPolygon({ "type": "Point", "coordinates": marker }, polygon)
        if (result) {
            _region = polygon.name
        }
    })

    return _region
}

export const getCurrentVersion = () => {
    let version = ''
    const metas = document.getElementsByTagName('meta')
    for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('name') === 'build-version') {
            return version = metas[i].getAttribute('build-version')
        }
    }
    return version
}


export function download(content, fileName, contentType) {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
}

export function checkTermsAndConditions(user) {
    const { uid } = user || {}
    //console.log('checkTermsAndConditions', displayName, uid)

    let accepted = window.sessionStorage.getItem(`termsConditions-${uid}`) === 'true' ? true : false

    //console.log('accepted', accepted)

    getClientData(uid)
        .then(client => {
            //console.log('getClientData', client.termsConditions)
            if (client.termsConditions)
                return window.sessionStorage.setItem(`termsConditions-${uid}`, `true`)

            return navigate("/termsConditions")
        })
        .catch(error => {
            if (accepted)
                return navigate("/termsConditions")
        })
}


export const sortByValue = (array, value) => {
    const compare = (a, b) => {
        if (a[value].toLowerCase() < b[value].toLowerCase()) {
            return -1;
        }
        if (a[value].toLowerCase() > b[value].toLowerCase()) {
            return 1;
        }
        return 0;
    }
    return array.sort(compare)
}

// Verificar si el navegador es compatible con el proveedor de Google
export const checkBrowserCompatibility = () => {
    const userAgent = navigator.userAgent
    const incompatibleBrowsers = ["Instagram", "FB"]

    // The browser is not compatible
    for (let browser of incompatibleBrowsers) {
        if (userAgent.includes(browser)) {
            return false
        }
    }

    return true
}

export const downloadJSON = (data) => {
    try {
        const blob = new Blob([JSON.stringify(data)], { type: 'application/json' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'data.json'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    } catch (error) {
        console.error('Error al generar JSON de ', data)
    }
}

export const arraysEqual = (a1, a2) =>
    a1.length === a2.length && a1.every((o, idx) => objectEqual(o, a2[idx]))


export const objectEqual = (object1, object2) => {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)

    if (keys1.length !== keys2.length) {
        // console.log('Cantidad de elementos distinto: ', keys1.length, ' !== ', keys2.length)
        return false
    }

    for (let key of keys1) {
        if (object1[key].toDate && object1[key].toDate() instanceof Date) {
            const time1 = object1[key].toDate().getTime()
            const time2 = object2[key].toDate().getTime()
            if (time1 !== time2) {
                // console.log('Diferencia en fechas: ', key, time1, time2)
                return false
            }
        }
        else if (Array.isArray(object1[key])) {
            if (!arraysEqual(object1[key], object2[key])) {
                // console.log('Diferencia en listado: ', key)
                return false
            }
        }
        else if (object1[key] !== object2[key]) {
            // console.log('Diferencia en ', key, object1[key], object2[key])
            return false
        }
    }
    return true
}



import React from "react"
import {
    Button,
    ButtonGroup,
    TextField,
} from "@material-ui/core"
import useMediaQuery from '@material-ui/core/useMediaQuery'

const GroupedButtons = ({ value, setValue = () => { }, label = '', enableSm, width = '100%', limit = 50, disabled = false }) => {
    const matches = useMediaQuery('(min-width:650px)')

    const handleIncrement = () => setValue(limitValidation(Number(value) + 1))
    const handleDecrement = () => setValue(limitValidation(Number(value) - 1))

    const handleChangeText = e => setValue(limitValidation(Number(e.target.value)))

    const limitValidation = (val) => val >= limit ? limit : val

    if (!matches && enableSm)
        return (
            <TextField
                color="primary"
                value={value || 0}
                onChange={handleChangeText}
                label={label}
                type='number'
                style={{ width: width }}
            />
        )

    return (
        <ButtonGroup
            size="small"
            aria-label="small outlined button group"
            disableelevation={true}
            disableripple={true}
            disablefocusripple={true}
            style={{ width: '100%' }}
            disabled={disabled}
        >
            <Button color="primary" onClick={handleDecrement}>-</Button>
            <TextField
                color="primary"
                value={value || 0}
                onChange={handleChangeText}
                label={label}
                style={{ height: '100%', width: '100%' }}
            />
            <Button color="primary" onClick={handleIncrement}>+</Button>
        </ButtonGroup>
    )


}

export default GroupedButtons
//https://react-leaflet.js.org/

import React from 'react'
import { Map, TileLayer, Polygon, Popup, Marker, Tooltip } from 'react-leaflet'
import { DeliveryMarker } from './deliveryMarker'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { firebasePointsToArray } from '../../utils'
import {
    Grid,
    Typography,
    FormControlLabel,
    Checkbox,
    IconButton,
    FormControl,
} from '@material-ui/core'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk'
import { getColorByName } from '../../utils/colors'
import Context from '../../context/mainContext'
import CustomMarker from './customMarker'
import moment from 'moment'
import 'moment/locale/es'
import useMainContext from '../../hooks/useMainContext'
import { normalizeOrderAddress } from '../../utils/address'
moment.locale('es')
//import deliveryImg from '../../img/delivery.png'
/* import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon */
L.Icon.Default.imagePath =
    '../node_modules/leaflet'

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});


//const url = 'http://{s}.tile.osm.org/{z}/{x}/{y}.png' //"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" //'https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png'


const styles = {
    wrapper: {
        height: '80vh',
        width: '100%',
        margin: '0 auto',
        display: 'flex'
    },
    map: {
        flex: 1
    }
}


const MapComponent = ({ mapZoom = null, deliveryPosition = null, centerMap = null, regions = [], markerPosition = null, showMarker = false, getPosition = () => { }, client = { name: 'Prueba' }, orderStates = [], orders = [], updateOrder = () => { }, clients = [] }) => {
    const { getPaymentMethodName, config = {}, businessInfo } = useMainContext()

    // console.log('MapComponent: ', config)
    const positionToObj = position => {
        let lat = config.mapCenter ? config.mapCenter[0] : (businessInfo?.position?.at(0))
        let lng = config.mapCenter ? config.mapCenter[1] : (businessInfo?.position?.at(1))

        if (Number(position?.length) > 0) {
            return { lat: position[0], lng: position[1] }
        }

        return { lat, lng }

    }


    const [currentPos, setCurrentPos] = React.useState(() => positionToObj(markerPosition))
    const [zoom, setZoom] = React.useState(mapZoom ? mapZoom : config.mapZoom ? config.mapZoom : 14)
    //console.log('markerPosition ', markerPosition)

    const handleClick = e => {
        //console.log('current position >> ' , e.latlng)
        setCurrentPos(e.latlng)
        getPosition(e.latlng)
    }

    const handleDragged = e => {
        //console.log('current position  >> ', e.target._latlng)
        setCurrentPos(e.target._latlng)
        getPosition(e.target._latlng)
    }

    const onViewportChanged = viewport => {
        //console.log('onViewportChanged',viewport)
        setZoom(viewport.zoom)
    }


    return (
        <div style={styles.wrapper}>
            <Map onViewportChanged={onViewportChanged} style={styles.map} onclick={handleClick} center={centerMap ? positionToObj(centerMap) : config.mapCenter ? config.mapCenter : [0, 0]} zoom={zoom}>
                <TileLayer url={config.mapURL ? config.mapURL : 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'} />
                {
                    regions.map(reg =>
                        <Polygon key={reg.id} positions={firebasePointsToArray(reg.positions)} name={reg.name} color={getColorByName(reg.color)} >
                            <Popup>
                                <p>{reg.name}</p>
                            </Popup>
                        </Polygon>
                    )
                }
                {
                    deliveryPosition && <DeliveryMarker position={positionToObj(deliveryPosition)} draggable={false}>
                        <Popup position={positionToObj(deliveryPosition)}>
                            {'Mi ubicacion'}
                        </Popup>
                    </DeliveryMarker>
                }
                {showMarker && markerPosition && <Marker ondragend={handleDragged} position={currentPos} draggable={true}>
                    <Popup position={currentPos}>
                        {`Dirección: ${normalizeOrderAddress(client.address)} ${client.number || ''}`}
                        {/* Current location: <pre>{JSON.stringify(currentPos, null, 2)}</pre> */}
                    </Popup>
                </Marker>
                }
                <Context.Consumer>
                    {
                        ({ isAuth, isAdmin, isDelivery, isChef, paymentMethods }) =>
                            orders.map(o =>
                                <CustomMarker isAdmin={isAdmin} key={o.id} order={o} position={positionToObj(o.position)} draggable={false}>
                                    <Tooltip permanent={true} sticky={false} opacity={0.8} direction={'bottom'}>
                                        {o.cod}
                                    </Tooltip>
                                    <Popup position={positionToObj(o.position)}>
                                        <Grid container spacing={1} style={{ paddingTop: 10, minWidth: 270 }}>
                                            <Grid item xs={6} container justify="flex-start">
                                                <Typography component="div" variant="body1">{`Cod: ${o.cod}`} </Typography>
                                            </Grid>
                                            <Grid item xs={6} container justify="flex-end" >
                                                <Typography component="div" variant="body1" align='rigth'>{moment(new Date(o.date.seconds * 1000)).fromNow()} </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography component="div" variant="body2">{o.client || o.clientName} </Typography>
                                                <Typography component="div" variant="body2">{o.pickup ? 'RETIRA EN EL LOCAL' : normalizeOrderAddress(o.address)} </Typography>
                                            </Grid>
                                            <Grid item xs={12} container justify="center" alignItems="center">
                                                <Grid item xs={6}>
                                                    <a style={{ textDecoration: 'none', color: 'green', display: 'block', textAlign: 'center', alignItems: 'center' }} href={`https://api.whatsapp.com/send?phone=${o.clientPhone || o.phone}&text=Hola,%20su%20pedido%20esta%20en%20camino`} target="_blank">
                                                        <IconButton style={{ color: 'green' }} aria-label="Whatsapp" >
                                                            <WhatsAppIcon />
                                                        </IconButton>
                                                        <Typography component="div" variant="body2" style={{ color: 'green' }}>Escribir</Typography>
                                                    </a>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <a style={{ textDecoration: 'none', display: 'block', textAlign: 'center', alignItems: 'center' }} href={`tel:${o.clientPhone || o.phone}`} >
                                                        <IconButton color="primary" aria-label="Llamar">
                                                            <PhoneInTalkIcon />
                                                        </IconButton>
                                                        <Typography component="div" variant="body2" color="primary" >Llamar</Typography>
                                                    </a>
                                                </Grid>
                                            </Grid>

                                            {
                                                isAdmin &&
                                                <Grid item xs={12}>
                                                    <Typography >{o.deliveryName && `Repartidor: ${o.deliveryName}`}</Typography>
                                                </Grid>
                                            }

                                            <Grid item xs={12}>
                                                <Typography >Notas: {o.notes || ''}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography component="div" variant="body2">{getPaymentMethodName({ order: o })} </Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{ margin: '10px 0 10px 0' }}>
                                                <Typography component="div" variant="body1" style={{ fontWeight: 'bold' }}>{`Total: $${o.amount}`}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled={o.orderState === 'Entregado'}
                                                            checked={o.orderState === 'Entregado'}
                                                            onChange={e => o.orderState !== 'Entregado' && updateOrder({ ...o, orderState: 'Entregado' })}
                                                            name="checkedEntregado"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Entregado"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Popup>
                                </CustomMarker>
                            )
                    }
                </Context.Consumer>
                {
                    clients.filter(o => o.position).map(o =>
                        <Marker key={o.id} position={positionToObj(o.position)} draggable={false}>
                            <Popup position={positionToObj(o.position)}>
                                <Typography>
                                    {o.name}
                                    <br />
                                    {o.phone}
                                    <br />
                                    {normalizeOrderAddress(o.address)}
                                </Typography>
                            </Popup>
                        </Marker>
                    )
                }
            </Map>
        </div>
    )
}

MapComponent.defaultProps = {
    zoom: 14,
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
}

export default MapComponent
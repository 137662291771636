import React, { lazy, Suspense, Fragment } from 'react'
import Context from './context/mainContext'
import { Router } from '@reach/router'
import SplashPage from './pages/splashPage'
import CartPage from './pages/cartPage'
import MyAccountPage from './pages/myAccountPage'
import BusinessInfoPagePublic from './pages/businessInfoPagePublic'
import ProductsPagePublic from './pages/productsPagePublic'
import HomePage from './pages/homePage'
import OrderPage from './pages/orderPage'
import TermsConditionsPage from './pages/termsConditionsPage'
import MyOrdersPage from './pages/myOrdersPage'
const Layout = lazy(() => import('./components/layout'), 'default')
const OrdersPage = lazy(() => import('./pages/ordersPage'), 'default')
const OrderAllocationPage = lazy(() => import('./pages/orderAllocationPage'), 'default')
const ProductsPage = lazy(() => import('./pages/productsPage'), 'default')
const NewOrderPage = lazy(() => import('./pages/newOrderPage'), 'default')
const MapPage = lazy(() => import('./pages/mapPage'), 'default')
const BusinessInfoPage = lazy(() => import('./pages/businessInfoPage'), 'default')
const ConfigPage = lazy(() => import('./pages/configPage'), 'default')
const ReportsPage = lazy(() => import('./pages/reportsPage'), 'default')
const ClientsPage = lazy(() => import('./pages/clientsPage'), 'default')
const StaffPage = lazy(() => import('./pages/staffPage'), 'default')
const EditOrderPage = lazy(() => import('./pages/editOrderPage'), 'default')
const GiftsPage = lazy(() => import('./pages/giftsPage'), 'default')
const DeliveriesPage = lazy(() => import('./pages/deliveriesPage'), 'default')
const CashRegisterPage = lazy(() => import('./pages/cashRegisterPage'), 'default')
const NotesVersionWithData = lazy(() => import('./containers/notesVersionWithData'), 'default')
const StockPage = lazy(() => import('./pages/stockPage'), 'default')
const AddressesPage = lazy(() => import('./pages/addressesPage'), 'default')
const BeepersPage = lazy(() => import('./pages/beepersPage'), 'default')
const TablesPage = lazy(() => import('./pages/tablesPage'), 'default')
const ZonesPage = lazy(() => import('./pages/zonesPage'), 'default')

const userAccess = (isAdmin, isDelivery, isChef, isAuth, user, config, modules) => {
  if (isAdmin)
    return (
      <Fragment>
        <NotesVersionWithData />
        <Router>
          <OrdersPage path='/' />
          <HomePage path='/home' />
          <OrdersPage path='/orders' />
          <NewOrderPage path='/newOrder' isAdmin={isAdmin} />
          <EditOrderPage path='/editOrder/:id' isAdmin={isAdmin} />
          <ProductsPage path='/products' />
          {
            modules?.tables && <TablesPage path='/tables' />
          }
          {
            modules?.beepers && <BeepersPage path='/beepers' />
          }
          <ClientsPage path='/clients' />
          <StaffPage path='/staff' />
          <MapPage path='/map' isAdmin={isAdmin} />
          <ReportsPage path='/reports' />
          <ConfigPage path='/config' />
          <BusinessInfoPage path='/businessInfo' />
          <BusinessInfoPagePublic path='/acerca' />
          <ProductsPagePublic path='/productos' />
          <MyAccountPage path='/cuenta' />
          <GiftsPage path='/gifts' />
          <DeliveriesPage path='/deliveries' />
          <CashRegisterPage path='/cashRegister' />
          <StockPage path='/stock' />
          <TermsConditionsPage path='termsConditions' />
          <ZonesPage path='/zones' />
        </Router>
      </Fragment>

    )
  else if (isChef)
    return (
      <Router>
        <OrdersPage path='/orders' />
        <MapPage path='/map' isChef={isChef} />
        <ProductsPagePublic path='/productos' />
        <MyAccountPage path='/cuenta' />
        <StockPage path='/stock' />
        <TermsConditionsPage path='termsConditions' />
        <HomePage path='/*' />
      </Router>
    )
  else if (isDelivery)
    return (
      <Router>
        <OrderAllocationPage path='/orderAllocation' />
        <OrdersPage path='/orders' />
        <MyAccountPage path='/cuenta' />
        <ProductsPagePublic path='/productos' />
        <TermsConditionsPage path='termsConditions' />
        <HomePage path='/*' />
      </Router>
    )
  else
    return (
      <Router>
        <BusinessInfoPagePublic path='/acerca' />
        <ProductsPagePublic path='/productos' />
        <CartPage path='/carrito' />
        <TermsConditionsPage path='termsConditions' />
        {
          isAuth &&
          <>
            <MyAccountPage path='/cuenta' user={user} config={config} />
            <OrderPage path='/order/:id' config={config} />
            <AddressesPage path='/direcciones' user={user} config={config} />
            <MyOrdersPage path='/misPedidos' />
          </>
        }
        <HomePage path='/*' />
      </Router>
    )
}


function App() {
  return (
    <Suspense fallback={<SplashPage />}>
      <Context.Consumer>
        {
          ({ isAuth, isAdmin, isDelivery, isChef, user, config, modules }) =>
            <Layout user={user} config={config}>
              {userAccess(isAdmin, isDelivery, isChef, isAuth, user, config, modules)}
            </Layout>
        }
      </Context.Consumer>

    </Suspense>
  )
}

export default App

import moment from 'moment';

Date.prototype.addDays = function (days) {
    this.setDate(this.getDate() + days)
    return this
}

export const spanishDaysArray = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"]

export const spanishMonthArray = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

export const isWeekend = date => {
    const dt = new Date(date)
    if (dt.getDay() === 6 || dt.getDay() === 0) {
        return true
    }
    return false
}

export const isHoliday = dates => {
    const now = new Date()
    let result = false
    dates.forEach(day => {
        day = timestampToDate(day)
        //console.log('day ', day.getDay(), now.getDay())
        //console.log('month ', day.getMonth(), now.getMonth())
        if (day.getDay() === now.getDay() && day.getMonth() === now.getMonth())
            result = true
    })
    return result
}

export const timestampToDate = ts => new Date(ts.seconds * 1000)

/**
* @return {string} YYYY-MM-DDTHH:MM
*/
export const toISOString = date =>
    date.toISOString ?
        date.toISOString().split('.')[0].substring(0, 16)
        :
        timestampToDate(date).toISOString().split('.')[0].substring(0, 16)
//'YYYY-MM-DDTHH:MM:SS'

export const toDate = date => {
    if (date.seconds) {
        return timestampToDate(date)
    } else {
        return date
    }
}

// Fecha en formato mes sistema, Enero = 0
export const toYYYYMMString = date => `${date.getFullYear()}${date.getMonth()}`



export const getDaysArray = ({ startDate, endDate, addFn, interval, excludes }) => {
    addFn = addFn || Date.prototype.addDays
    interval = interval || 1

    var retVal = []
    var current = new Date(startDate)

    //console.log('startDate', startDate)

    while (current <= endDate) {
        //console.log(current.getDay(), 'in', excludes)
        if (!excludes.includes(current.getDay())) {
            retVal.push({ date: new Date(current) })
        }
        current = addFn.call(current, interval)
    }
    return retVal
}


// mm/dd/yyyy
export const formatDate = (date) => {
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('/')
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0')
}

// Función auxiliar para convertir el formato HH:mm a minutos desde la medianoche
function convertToMinutes(timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
}

export const isLocalOpen = (schedule = []) => {
    const now = moment();
    const currentDay = spanishDaysArray[now.day()];
    const currentTime = now.hours() * 60 + now.minutes();

    const todaySchedule = schedule.find((day) => day.day === currentDay);

    if (todaySchedule) {
        const fromTime = convertToMinutes(todaySchedule.from);
        let toTime = convertToMinutes(todaySchedule.to);

        // Si toTime es menor que fromTime, entonces corresponde al día siguiente
        if (toTime < fromTime) {
            toTime += 24 * 60; // Agregamos 24 horas en minutos para representar el día siguiente
        }

        if (currentTime >= fromTime && currentTime <= toTime) {
            return true;
        }
    }

    const yesterdayIndex = (now.day() - 1 + 7) % 7;
    const yesterday = spanishDaysArray[yesterdayIndex];
    const yesterdaySchedule = schedule.find((day) => day.day === yesterday);

    if (yesterdaySchedule) {
        const toTimeYesterday = convertToMinutes(yesterdaySchedule.to);

        if (currentTime < toTimeYesterday) {
            return true;
        }
    }

    return false;
};


export const getTodayFromBusinessSchedule = (schedule = []) => {
    try {
        const now = new Date()
        const currentDay = spanishDaysArray[now.getDay()]

        // Buscar el día actual en el listado
        const result = schedule.find(day => day.day === currentDay)
        return result
    } catch (error) {
        console.error(error)
        return null
    }
}

export const convertScheduleBusinessDayToDate = (value) => {
    try {
        const { from = '19:00', to = '01:00', day } = value || {};

        // Si 'to' es mayor que 'from', significa que el horario corresponde al día siguiente
        const isOvernight = moment(to, 'HH:mm').isBefore(moment(from, 'HH:mm'));

        // Use moment para parsear la fecha actual y la fecha del próximo día si es necesario
        const fromDate = moment(day + ' ' + from, 'dddd HH:mm');
        const toDate = isOvernight
            ? moment(day, 'dddd').add(1, 'day').add(moment(to, 'HH:mm').diff(moment('00:00', 'HH:mm')))
            : moment(day + ' ' + to, 'dddd HH:mm');

        return {
            day,
            from,
            to,
            fromDate: fromDate.toDate(),
            toDate: toDate.toDate(),
        };
    } catch (error) {
        console.error(error);
        return value;
    }
};

export const openSoon = (config = {}, schedule = []) => {
    try {
        const { canScheduleOrder, canScheduleBeforeHours = 4 } = config

        // si el local esta abierto, devuelvo true sin seguir haciendo calculos
        if (isLocalOpen(schedule)) {
            return true

            // si el local esta cerrado, me fijo si esta habilitada la opcion de poder agendar pedidos
        }
        // else if (!canScheduleOrder) {
        //     return false
        // }


        const today = getTodayFromBusinessSchedule(schedule)
        const todayParsed = convertScheduleBusinessDayToDate(today)

        console.log('todayParsed', todayParsed)

        if (moment(todayParsed.fromDate).isSame(moment(todayParsed.toDate))) {
            console.log('isSame')
            return false
        }

        if (moment(todayParsed.fromDate).isBefore(moment(todayParsed.toDate))) {
            console.log('isAfter')
            return true
        }

        // if (today || todayParsed) {
        //     // calcular si las horas que faltan para abrir el local son menores a canScheduleBeforeHours
        //     const currentTime = moment()
        //     const openingTime = moment(todayParsed.fromDate)
        //     const hoursUntilOpening = openingTime.diff(currentTime, 'hours')

        //     return hoursUntilOpening <= canScheduleBeforeHours
        // }

        return false
    } catch (error) {
        return false
    }
}
import React, { useState, useEffect } from 'react'
import {
    Grid,
    FormControl,
    FormControlLabel,
    Button,
    Checkbox,
    Typography,
    Card,
    CardContent,
    useMediaQuery,
    TextField
} from '@material-ui/core'
//import clsx from 'clsx'
import Dialog from '../../dialog'
import { useStyles } from './styles'
import { ProductVariant } from './productVariant'
import { createSmartNotesFromOrderProducts, formatSmartNotes, smartNotesToList } from '../../../utils/productEdit'
import SmartNotes from '../smartNotes'

const voidF = () => { }

export default function EditProductOrder({ isAdmin, children, itemToEdit = {}, openForm, setOpenForm = voidF, onConfirm = voidF }) {
    const classes = useStyles()
    const [list, setList] = useState([])
    const [smartNotes, setSmartNotes] = useState('')

    const notSmall = useMediaQuery('(min-width:600px)')
    const [dialogParams, setDialogParams] = useState({
        maxWidth: 'md',
        itemXs: 4
    })


    useEffect(() => {
        return generateDialogParams()
    }, [notSmall])

    useEffect(() => {
        const renderData = () => {
            const { smartComposition = [] } = itemToEdit
            generateDialogParams()
            setList(smartComposition)
        }
        return renderData()
    }, [itemToEdit])

    useEffect(() => {
        // const createSmartNotes = () => {
        //     let notes = ''
        //     //console.log('createSmartNotes', list)
        //     list.forEach((product) => {
        //         const { composition = [], variants = [] } = product
        //         let firstNote = ''
        //         if (list.length > 1)
        //             firstNote = 'Una ' // `${numberToCardinals[_cod]} `

        //         let _notes = firstNote
        //         composition.forEach(supply => {
        //             let text = supply.shortDescription || supply.description || supply.name || ''
        //             text = text.toUpperCase()
        //             if ((supply.optional || supply.variant) && !supply.checked) {
        //                 _notes += `Sin ${text}, `
        //             }
        //             else if (supply.variantDescription)
        //                 _notes += `${supply.variantDescription}, `

        //         })
        //         if (product.variantDescription)
        //             _notes += `${product.variantDescription}, `

        //         variants.forEach(variant => {
        //             let result = `${variant.variantDescription}, `
        //             const subVariants = composition.find(c => c.id === variant.variantId)?.variants?.map(v => v.variantDescription).join()
        //             if (subVariants) {
        //                 result = `${variant.variantDescription} (${subVariants}), `
        //             }
        //             _notes += result
        //         })

        //         if (_notes !== firstNote) {
        //             notes += _notes.slice(0, -2) + ' | '
        //         }
        //     })
        //     notes = notes.slice(0, -3).toUpperCase()

        //     setSmartNotes(notes)
        // }
        // return createSmartNotes()
        const _smartNotes = createSmartNotesFromOrderProducts(list)
        setSmartNotes(_smartNotes)
    }, [list])

    const generateDialogParams = () => {
        const { smartComposition = [] } = itemToEdit

        if (smartComposition.length === 1 || !notSmall) {
            return setDialogParams({
                maxWidth: 'xs',
                itemXs: 12
            })
        }

        if (smartComposition.length === 2) {
            return setDialogParams({
                maxWidth: 'sm',
                itemXs: 6
            })
        }

        return setDialogParams({
            maxWidth: 'md',
            itemXs: 4
        })
    }


    const handleConfirm = () => {
        const product = {
            ...itemToEdit,
            smartComposition: list,
            smartNotes
        }
        onConfirm(product)
        setOpenForm(false)
    }


    const onCheck = (product, supply) => {
        const items = list.filter((item) => {
            if (product._cod === item._cod) {
                item.composition = item.composition.map(sup => {
                    if (sup.id === supply.id) {
                        return {
                            ...sup,
                            checked: !sup.checked
                        }
                    }
                    return sup
                })

                return supply
            }
            return item
        })
        setList(items)
    }

    const supplyDescription = (supply) => {
        let text = supply.shortDescription || supply.description || supply.name || ''
        return text.toUpperCase()
    }


    const handleChangeNote = (itemToEdit, notes) => {
        const items = list.filter((item) => {
            if (item._cod === itemToEdit._cod) {
                item.notes = notes
                return item
            }
            return item
        })
        setList(items)
    }


    return (
        <>
            <Dialog disableBackdropClick={true} message={itemToEdit.name} fullWidth={true} maxWidth={dialogParams.maxWidth} open={openForm} setOpen={setOpenForm} acceptText={null} cancelText={null} >
                {children}
                <FormControl className={classes.formControl}>
                    {
                        isAdmin && <>
                            <Typography>Comentarios:</Typography>
                            <SmartNotes smartNotes={smartNotes} />
                        </>
                    }
                    <Grid direction="row" container className={classes.listContainer}>
                        {
                            list?.map((item, i) =>
                                <Grid key={item._cod} item xs={dialogParams.itemXs}>
                                    <Card className={classes.card} >
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">#{item._cod}</Typography>
                                            <Grid container>
                                                {
                                                    item.composition?.filter(s => s.optional).map((supply) =>
                                                        <Grid item xs={12} key={supply.id}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox checked={supply.checked} onChange={e => onCheck(item, supply)} value={supply.id} />
                                                                }
                                                                label={supplyDescription(supply)}
                                                                style={{ paddingLeft: 10 }}
                                                            />
                                                        </Grid>
                                                    )
                                                }
                                                {
                                                    <ProductVariant product={item} list={list} setList={setList} />
                                                }
                                                <Grid item xs={12} >
                                                    <TextField label='Aclaración' value={item.notes || ''} onChange={(e) => handleChangeNote(item, e.target.value)} fullWidth />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        }

                    </Grid>
                    <Grid
                        container
                        direction="row-reverse"
                        justify="flex-end"
                        alignItems="flex-start"
                        className={classes.gridActions}
                    >
                        <Button variant="outlined" color="primary" onClick={handleConfirm} >Confirmar</Button>
                    </Grid>
                </FormControl>
            </Dialog>
        </>
    )
}
import React, { useState, useEffect } from 'react'
import {
    Box,
    Stepper,
    Step,
    StepLabel,
    Typography,
    Grid,
    Button,
} from '@material-ui/core'
import { useStyles } from './styles'
import useMainContext from '../../../hooks/useMainContext'
import { LocationOn } from '@material-ui/icons'

const defaultSteps = [
    'Pendiente',
    'En preparación',
    'En reparto',
    'Entregado',
]


export const OrderView = ({ order, config }) => {
    const classes = useStyles()
    const [activeStep, setActiveStep] = useState(0)
    const { paymentMethods, businessInfo } = useMainContext()
    const [steps, setSteps] = useState(defaultSteps)

    useEffect(() => {
        const { orderState, pickup } = order
        let { firstState = 'En cocina', delayState = 'Demorado', cookedState = 'Finalizado', assignedState = 'En reparto', lastState = 'Entregado' } = config
        let step = 0
        if (orderState === firstState || orderState === delayState || (orderState === cookedState && !pickup))
            step = 1
        if (orderState === assignedState || (orderState === cookedState && pickup))
            step = 2
        if (orderState === lastState)
            step = 3

        if (pickup) {
            setSteps([
                'Pendiente',
                'En preparación',
                'Para retirar',
                'Entregado',
            ])
        }

        setActiveStep(step)
    }, [order, config])

    const pointsMessage = () => {
        const { amount = 0 } = order || {}
        const { pointsPerUnit = 0 } = config || {}
        const pointsToAdd = Math.floor(amount / pointsPerUnit)

        if (pointsToAdd < 1)
            return 'No se acreditarán puntos con esta compra.'

        if (pointsToAdd === 1)
            return 'Se agreditará un punto al entregarse el pedido.'

        if (pointsToAdd > 1)
            return `Se acreditarán ${pointsToAdd} puntos al entregarse el pedido.`
    }

    const isStepFailed = (index) => {
        let { canceledState = 'Cancelado' } = config
        const { orderState } = order


        if (order && orderState === canceledState && index === activeStep)
            return {
                failed: true,
                failedMessage: 'Pedido cancelado por el local.'
            }


        return {
            failed: false,
            failedMessage: ''
        }
    }

    const showInMapClicked = () =>
        order.position?.length > 0 && window.open("https://maps.google.com?q=" + order.position.at(0) + "," + order.position.at(1))

    const renderPaymentMethod = () => {
        const { paymentMethod } = order
        const method = paymentMethods?.find(m => m.method === paymentMethod)
        if (!method)
            return ''
        return `Método de pago: ${method.name || ''}`

    }

    return (
        <Box sx={{ width: '100%' }}>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.receiptContainer}
            >
                <Grid item xs={12}>
                    <Typography variant="h1" align='center'>Cod: {order.cod}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" component="p" align='center'>Total: ${order.amount || 0}</Typography>
                </Grid>
                {
                    order.pickup &&
                    <Button
                        color="primary"
                        className={classes.button}
                        startIcon={<LocationOn>send</LocationOn>}
                        onClick={showInMapClicked}
                    >
                        {`Retira en local: ${businessInfo?.address || ''}`}
                    </Button>
                }
                <Grid item xs={12}>
                    <Typography variant="p" component="p" align='center'>{order.notes || ''}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="p" component="p" align='center'>
                        {renderPaymentMethod()}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="p" component="p" align='center'>{pointsMessage()}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{ width: '100%', padding: 0, margin: 0 }}>
                        <Stepper activeStep={activeStep} alternativeLabel style={{ width: '100%', padding: 0, margin: 0 }}>
                            {steps.map((label, index) => {
                                const labelProps = {}

                                const stepState = isStepFailed(index)
                                if (stepState.failed) {
                                    labelProps.optional = (
                                        <Typography variant="caption" color="error" component="p" align='center'>
                                            {stepState.failedMessage}
                                        </Typography>
                                    )

                                    labelProps.error = true
                                }

                                return (
                                    <Step key={label}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                )
                            })}
                        </Stepper>
                    </Box>
                </Grid>
                {
                    (config.canceledState === order.orderState && order.cancelledMessage) &&
                    <Grid item xs={12} style={{ padding: 25 }}>
                        <Typography variant="p" component="p" color="error" style={{ fontWeight: 'bold', width: '100%', fontSize: 16 }}>Motivo de cancelación</Typography>
                        <Typography variant="p" component="p" color="error" style={{ fontSize: 14, width: '100%', }} >{order.cancelledMessage}</Typography>
                        {
                            order.usedPoints > 0 && <Typography variant="p" component="p" color="error" style={{ fontSize: 14, width: '100%', marginTop: 20 }} >{`Se devolverán ${order.usedPoints} puntos usados para este pedido.`}</Typography>
                        }

                    </Grid>
                }

            </Grid>
        </Box>

    )
}

export default OrderView

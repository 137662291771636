import React, { useState, useEffect } from 'react'
import { getClientData, saveClient, getClientPoints } from '../firebase/obj/client'
import { saveOrder } from '../firebase/obj/order'
import ClientOrderForm from '../components/order/orderForm/client'
import { navigate } from '@reach/router'
import { validateOrder } from '../utils/productEdit'
import { getClientAdressesRealTime, saveAddress, updateAddress } from '../firebase/obj/clientAddress'
import AddressForm from '../components/client/addressForm'
import useMainContext from '../hooks/useMainContext'

export const CartWithData = ({ cart, setCart = () => { } }) => {
    const { user, paymentMethods, config } = useMainContext()
    const [notification, setNotification] = useState({})
    const [order, setOrder] = useState({ createdByClient: true, productsList: [], pickup: false })
    const [client, setClient] = useState(null)
    const [clientPoints, setClientPoints] = useState(0)
    const [openAddressForm, setOpenAddressForm] = useState(false)
    const [addresses, setAddresses] = useState([])

    useEffect(() => {
        //console.log('paymentMethod', paymentMethods, cart)
        if (paymentMethods?.length === 1 && cart && !cart.paymentMethod) {
            const paymentMethod = paymentMethods.at(0).method
            //console.log('paymentMethod', paymentMethod)
            setCart({ ...cart, paymentMethod })
        }
    }, [paymentMethods, cart])

    useEffect(() => {
        let addressId = cart.addressId
        if (addressId)
            return

        addressId = addresses.find(e => e.principal)?.id || addresses?.length === 1 ? addresses.at(0).id : ''

        setOrder({
            ...order,
            addressId
        })
    }, [addresses, cart])

    useEffect(() => {
        const fetchClientData = async () => {
            const { productsList = [], addressId, paymentMethod = '', pickup = false } = cart
            const { firstClientState = 'Pendiente', deliveryPrice = 0 } = config || {}
            // console.log('user', user)
            if (client) {
                // console.log('client', client)
                const { name, email, id, phone = (user?.phoneNumber || '') } = client
                // console.log('phone', phone)
                const _order = {
                    ...order,
                    clientId: id,
                    clientName: name,
                    clientPhone: phone,
                    userEmail: email,
                    addressId,
                    productsList,
                    orderState: firstClientState,
                    deliveryPrice,
                    usedPoints: cart.usedPoints,
                    paymentMethod,
                    pickup
                }
                const amount = calculateTotalPrice(_order)
                return setOrder({
                    ..._order,
                    amount
                })
            }
            if (!user) {
                console.log('No hay usuario logueado')
                const _order = {
                    ...order,
                    productsList,
                    orderState: firstClientState,
                    deliveryPrice,
                    usedPoints: cart.usedPoints
                }
                const amount = calculateTotalPrice(_order)
                return setOrder({
                    ..._order,
                    amount
                })
            }

            getClientPoints(user.uid).then(data => {
                setClientPoints(data.points || 0)
            })

            return getClientData(user.uid).then(client => {
                const { name, email, id, phone } = client
                setClient(client)
                const _order = {
                    ...order,
                    clientId: id,
                    clientName: name,
                    clientPhone: phone,
                    userEmail: email,
                    addressId,
                    productsList,
                    orderState: firstClientState,
                    deliveryPrice,
                    usedPoints: cart.usedPoints,
                    paymentMethod,
                    pickup
                }
                getClientAdressesRealTime(user.uid, setAddresses)
                const amount = calculateTotalPrice(_order)
                setOrder({
                    ..._order,
                    amount
                })
            })
        }

        fetchClientData()

    }, [user, config, cart])

    const calculateTotalPrice = (order) => {
        // console.log('calculateTotalPrice')
        const { deliveryPrice = 0 } = config || {}
        const { productsList = [], pickup } = order
        let amount = pickup ? 0 : deliveryPrice

        productsList?.map((product) => {
            const { qty = 0, price = 0 } = product
            return amount += (Number(qty) * Number(price))
        })

        if (order.usedPoints > 0) {
            amount -= order.usedPoints
        }

        return amount
    }

    /**
     * Called when product qty changes or adds a new product
     */
    const setProductQty = (product, qty) => {
        let totalQty = 0

        if (qty < 1) qty = 0

        const productsList = cart.productsList?.map(item => {
            if (item.id === product.id) {
                totalQty += qty
                return { ...product, qty }
            }
            totalQty += item.qty

            return item
        })

        setCart({ ...cart, productsList, totalQty })
    }


    const updateClient = (order) => {
        getClientData(user.uid).then(client => {
            const { name, address, phone, id, position } = client

            if (!name || !address || !phone || !position) {

                client.id = id || user.uid
                client.name = name || order.client || order.clientName
                client.address = address || order.address
                client.phone = phone || order.phone || order.clientPhone
                client.position = position || order.position

                saveClient(client)
            }
        })

    }

    const handleSendOrder = () => {
        const _order = order
        _order.clientName = _order.clientName?.toUpperCase()
        _order.userEmail = user.email
        _order.createdByClient = true

        // console.log('handleSendOrder', order)

        if (!_order.usedPoints) {
            _order.usedPoints = 0
        }

        if (_order.pickup) {
            _order.addressId = ''
            _order.address = ''
            _order.position = []
        } else {
            const addressInfo = addresses.find(e => order.addressId === e.id) || {}
            _order.address = `${addressInfo.address}${addressInfo.number ? ` ${addressInfo.number}` : ''}`
            _order.position = addressInfo.position
            // _order.phone = addressInfo.phone
        }

        const validation = validateOrder(_order, paymentMethods)

        if (!validation.valid) {
            setNotification({
                open: true,
                autoHideDuration: 3000,
                severity: validation.severity || 'warning',
                message: validation.message,
            })
            return Promise.reject()
        }

        return saveOrder(_order).then((id) => {
            setNotification({
                open: true,
                autoHideDuration: 3000,
                severity: 'success',
                message: 'Pedido enviado!',
            })
            updateClient(_order)
            setCart({ productsList: [], totalQty: 0 })
            setTimeout(() => {
                navigate(`/order/${id}`)
            }, 3000)
        })
            .catch(error => {
                console.log('handleSendOrder saveOrder', error)
                setNotification({
                    open: true,
                    autoHideDuration: 3000,
                    severity: 'error',
                    message: error.message,
                })
            })
    }

    const handleDeleteProduct = product => {
        const productsList = cart.productsList?.filter(p => p.id !== product.id)
        const totalQty = cart.totalQty - product.qty
        setCart({ ...cart, productsList, totalQty })
    }

    const usedPointsHandler = (order, usedPoints) => {
        setCart({ ...cart, ...order, usedPoints })
    }

    const setOrderAddress = clientAddress => {
        const { id, address, number, phone, position } = clientAddress
        setCart({ ...cart, addressId: id, address: `${address} ${number}`, phone, position })
    }

    const handleSaveAddress = (data, onError = () => { }) => {
        // console.log('handleSaveAddress', user.uid, data)
        const { id } = data
        if (id)
            updateAddress(user.uid, data)
                .then(() => {
                    setOpenAddressForm(false)
                    setOrderAddress(data)
                })
                .catch(onError)

        saveAddress(user.uid, data)
            .then(({ id }) => {
                setOpenAddressForm(false)
                setOrderAddress({ ...data, id })
            })
            .catch(onError)
    }

    const setOrderHandler = (order) => {
        const { addressId, productsList } = order
        setOrder(order)
        setCart({ ...order, addressId, productsList })
    }

    return (
        <>
            <AddressForm
                selected={{ principal: (addresses.length === 0), id: '', position: [] }}
                openForm={openAddressForm}
                setOpenForm={setOpenAddressForm}
                saveAddress={handleSaveAddress}
                config={config}
            />
            <ClientOrderForm
                user={user}
                client={client}
                order={order}
                notification={notification}
                setOrder={setOrderHandler}
                setNotificationOpen={setNotification}
                setProductQty={setProductQty}
                sendOrder={handleSendOrder}
                deleteProduct={handleDeleteProduct}
                clientPoints={clientPoints}
                usedPointsHandler={usedPointsHandler}
                addresses={addresses}
                setOpenAddressForm={setOpenAddressForm}
                paymentMethods={paymentMethods}
            />
        </>
    )
}

export default CartWithData
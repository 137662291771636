import React from 'react'
import ReactDOM from 'react-dom'
import {
  createHistory,
  LocationProvider
} from "@reach/router"
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import Firebase from './firebase/firebase'
import Context from './context/mainContext'
import OrderContext from './context/orderContext'
import TablesContext from './context/tableContext'
import ZoneContext from './context/zoneContext'
import * as Sentry from '@sentry/react'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  debug: process.env.SENTRY_DEBUG
})

new Firebase()

ReactDOM.render(
  <LocationProvider >
    <Context.Provider>
      <ZoneContext.Provider>
        <TablesContext.Provider>
          <OrderContext.Provider>
            <App />
          </OrderContext.Provider>
        </TablesContext.Provider>
      </ZoneContext.Provider>
    </Context.Provider>
  </LocationProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (process.env.REACT_APP_ENV === 'dev') {
  //console.log('serviceWorker unregister')
  serviceWorker.unregister()
}
else if (process.env.REACT_APP_ENV === 'prod') {
  //console.log('serviceWorker register')
  serviceWorker.register()
}

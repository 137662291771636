import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
   Grid,
   Typography,
   List,
   ListItem,
   ListItemText,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
   schedule: {
      margin: theme.spacing(1),
   },
   description: {
      margin: theme.spacing(1),
   },
   ItemText: {
      width: 10
   },
   List: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
   }
}))


export default function businessInfo({ businessInfo, setInfo = () => { }, updateInfo = () => { } }) {
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const classes = useStyles()
   return (
      <Grid container>
         <Grid item xs={12} className={classes.description}>
            <Typography>
               {businessInfo.description}
            </Typography>
         </Grid>
         <Grid item xs={12} className={classes.schedule}>
            <Typography>
               Horarios
            </Typography>
            <List dense={true} className={classes.List}>
               {
                  businessInfo?.schedule?.map(d =>
                     <ListItem key={d.day} button style={d.from === d.to ? { color: 'red' } : {}}>
                        <ListItemText className={classes.ItemText}
                           primary={d.day}
                        />
                        {
                           d.from === d.to ?
                              <ListItemText className={classes.ItemText}
                                 primary={`Cerrado`} />
                              :
                              <React.Fragment>
                                 <ListItemText className={classes.ItemText}
                                    primary={`Desde: ${d.from}`}
                                 />
                                 <ListItemText className={classes.ItemText}
                                    primary={`Hasta: ${d.to}`}
                                 />
                              </React.Fragment>
                        }
                     </ListItem>
                  )
               }
            </List>
         </Grid>
      </Grid>
   )
}

import firebase from 'firebase/compat/app'
import {
    getDocument,
    saveDocument,
} from './abm'

const collection = 'topics'

export const getTopic = token => getDocument(token, collection)

export const saveTopic = data => firebase.app().firestore().collection(collection).doc(data.id).set({ ...data })
    .then(docRef => Promise.resolve())
    .catch(error => Promise.reject())

export const registerClientTopic = data => {
    firebase.messaging()
        .getToken()
        .then((token) => {
            getTopic(data.topic).then(data => {
                console.log('existe suscripcion del cliente')
            }).catch(err => {
                if (err.message === 'No se encontró registro en topics') {
                    saveTopic({
                        ...data,
                        token,
                    }).then(() => {
                        console.log('Se registro al topic', data.topic)
                    })
                }
            })
        })
}
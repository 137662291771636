import { keyBy } from "./index"

const generateSubTotal = (prod) => {
    //const prod = productsList?.find(p => p.id === prodId)        || {}
    let price = parseFloat(prod.price)
    let qty = parseFloat(prod.qty)

    return price * qty
}

export const calculateTotalOrder = ({ productsList = [], deliveryPrice = 0, discount = 0, extras = [], pickup, usedPoints = 0 }) => {
    let total = 0
    //console.log('calculateTotalOrder productsList', productsList)
    // console.log('calculateTotalOrder')

    extras?.forEach(e => total += e.amount)

    productsList?.forEach(p => {
        if (p.checked || p.qty > 0) {
            total += generateSubTotal(p)
        }
    })

    if (deliveryPrice !== '' && deliveryPrice !== null && !pickup)
        total += parseFloat(deliveryPrice)

    if (discount !== '' && discount !== null)
        total -= parseFloat(discount)

    if (usedPoints > 0) {
        total -= usedPoints
    }


    return total
}

export const validateAdminOrder = (order, paymentMethods) => {
    const validation = {
        valid: true,
        messagges: []
    }

    if (order?.type === 'table') {
        if (!order?.tableId) {
            validation.valid = false
            validation.messagges.push('Seleccione mesa')
        }
    }

    if (!order?.address && !order?.pickup && order?.type !== 'table') {
        validation.valid = false
        validation.messagges.push('Seleccione dirección')
    }
    if (!order?.clientName && order?.type !== 'table') {
        validation.valid = false
        validation.messagges.push('Seleccione cliente')
    }
    if (paymentMethods?.length > 0 && order?.type !== 'table') {
        if (!order?.paymentMethod) {
            validation.valid = false
            validation.messagges.push('Seleccione método de pago')
        }
    }
    if (order?.productsList?.length === 0) {
        validation.valid = false
        validation.messagges.push('Seleccione al menos un producto')
    } else {
        const _validation = validateOrderProductList(order?.productsList)
        if (!_validation.valid) {
            validation.valid = false
            _validation.messagges?.forEach(message => {
                validation.messagges.push(message)
            })
        }
    }

    return validation
}


export const validateOrderProductList = (productsList = []) => {
    const validation = {
        valid: true,
        messagges: []
    }

    let _validation = {
        valid: true,
        severity: 'success',
        message: 'Success'
    }
    let stop = false
    productsList?.map(product => {
        if (stop)
            return null

        if (product.smartComposition) {
            const { smartComposition = [] } = product

            for (let l = 0; l < smartComposition.length && !stop; l++) {
                const prod = smartComposition[l]
                _validation = validateProduct(prod, _validation)
                stop = _validation.break

                const { variants = [] } = prod

                if (_validation.valid) {
                    // eslint-disable-next-line no-loop-func
                    prod?.composition?.some(item => {
                        let __validation = {}

                        if (item.optional && !item.checked)
                            return false

                        validateProduct(item, __validation)
                        if (__validation.valid === false) {
                            const variant = variants.find(v => v.variantGroup === item.variantGroup)
                            // busco si para el mismo variantGroup ya hay otro elemento seleccionado
                            if (variant && variant?.variantId !== item.id)
                                return false
                            _validation = __validation
                        }
                        return __validation.valid
                    })
                }
            }

        } else {
            _validation = validateProduct(product, _validation)
            stop = _validation.break
        }

        return null
    })

    if (!_validation.valid) {
        validation.valid = false
        validation.messagges.push(_validation.message)
    }


    return validation
}

const validateProduct = (product, validation) => {
    const { composition = [], variants = [] } = product
    const variantsGrouped = keyBy(composition.filter(v => v.variant), 'variantGroup')
    const variantsGroupedSelected = keyBy(variants, 'variantGroup')


    // console.log('validateProduct', product)
    // console.log('variantsGrouped', variantsGrouped)
    // console.log('variantsGroupedSelected', variantsGroupedSelected)


    Object.keys(variantsGrouped).forEach(variantGroup => {
        const _variantGroup = variantGroup === 'undefined' ? 'Variedad' : variantGroup
        if (!variantsGroupedSelected[_variantGroup]) {
            validation.valid = false
            validation.severity = 'warning'
            validation.message = `No se ha seleccionado ${_variantGroup} para ${product.name || product.description}`
            validation.break = true
        }
    })

    return validation
}
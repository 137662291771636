import React, { useState, useEffect } from 'react'
import {
    Grid,
    Typography,
    Button,
    IconButton,
    TextField,
    List,
    ListItem,
    ListItemText,
    Slider,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
} from '@material-ui/core'
import { Link } from '@reach/router'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Dialog from '../../dialog'
import { loginWithGoogle } from '../../../firebase/login'
import Notification from '../../notification'
import InputNumber from '../../inputNumber'
import { useStyles } from './clientStyles'
import OrderProductEdit from '../orderProductEdit'
import { generateSmartComposition } from '../../../utils/productEdit'
import PaymentMethod from './payment'
import PickupOptions from './pickupOptions'
import useMainContext from '../../../hooks/useMainContext'
import SmartNotes from '../smartNotes'
import ScheduleOrder from '../orderSchedule'
import { isLocalOpen, openSoon } from '../../../utils/dateFunctions'

const voidF = () => { }

export const ClientOrderForm = ({ client, clientPoints = 0, user, order, notification, setOrder = voidF, setNotificationOpen = voidF, setProductQty = voidF, sendOrder = voidF, deleteProduct = voidF, usedPointsHandler = voidF, addresses, setOpenAddressForm = voidF, paymentMethods = [] }) => {
    const classes = useStyles()
    const [disabled, setDisabled] = useState(true)
    const [editLineOpen, setEditLineOpen] = useState(false)
    const [lineToEdit, setLineToEdit] = useState({})
    const [selectedAddress, setSelectedAddress] = useState('')
    const [dialog, setDialog] = useState({
        open: false,
        message: ''
    })
    const [usedPoints, setUsedPoints] = useState(0)
    const { config, businessInfo } = useMainContext()
    const [localOpen, setLocalOpen] = useState(true)
    const [isOpenSoon, setIsOpenSoon] = useState(false)

    const [notificationOpen, setOpen] = useState(false)
    const [autoHideDuration, setAutoHideDuration] = useState(3000)
    const [severity, setSeverity] = useState('success')
    const [notificationMessage, setNotificationMessage] = useState('')


    useEffect(() => {
        if (businessInfo) {
            const _isLocalOpen = isLocalOpen(businessInfo.schedule)
            // console.log('_isLocalOpen', _isLocalOpen)
            setLocalOpen(_isLocalOpen)
            if (config) {
                const _openSoon = openSoon(config, businessInfo.schedule)
                // console.log('_openSoon', _openSoon)
                setIsOpenSoon(_openSoon)
            }
        }
    }, [businessInfo, config])

    useEffect(() => {
        if (!notification.message)
            return setOpen(false)

        setOpen(notification.open)
        setAutoHideDuration(notification.autoHideDuration)
        setSeverity(notification.severity)
        setNotificationMessage(notification.message)
    }, [notification])

    useEffect(() => {
        disableControl()
        if (editLineOpen) {
            const { productsList = [] } = order
            const product = productsList?.find(p => p.id === lineToEdit.id)
            if (product) {
                //console.log('useEffect order product', product)
                const smartComposition = generateSmartComposition(product).smartComposition
                setLineToEdit({ ...product, smartComposition })
            }
        }
        //setSelectedAddress(order?.addressId || addresses.find(e => e.principal)?.id || '')
        // console.log('order', order)
        setSelectedAddress(order?.addressId || '')
    }, [order, addresses, config, localOpen, client])

    const disableControl = () => {
        let _disabled = false

        if (order.productsList?.length < 1) _disabled = true
        if (!user) _disabled = true
        if (!order.paymentMethod && paymentMethods?.length > 0) _disabled = true
        if (config?.requirePhoneValidation && !user?.phoneNumber) _disabled = true
        if (!localOpen && !isOpenSoon) _disabled = true

        setDisabled(_disabled)
    }

    const handleConfirmLineEdit = (prod) => {
        const { productsList } = order
        const _productsList = productsList?.map(product => {
            if (prod.id === product.id)
                return { ...prod }
            return { ...product }
        })
        setOrder({
            ...order,
            productsList: _productsList
        })
    }

    const handleEditProduct = product => {
        const smartComposition = generateSmartComposition(product).smartComposition
        setLineToEdit({ ...product, smartComposition })
        setEditLineOpen(true)
    }

    const handleDeleteProduct = (product) => {
        setDialog({
            open: true,
            message: `¿Desea borrar ${product.name || product.description || 'elemento'}?`,
            obj: product,
        })
    }

    const getProductQty = (product) => {
        const { productsList } = order
        const _prod = productsList?.find(prod => prod.id === product.id)
        const { qty = 0 } = _prod || {}
        return qty
    }

    const setDialogOpen = open => setDialog({ ...dialog, open })

    const changeUsedPoints = (event, points) => setUsedPoints(points)

    const changeUsedPointsCommited = event => usedPointsHandler(order, usedPoints)

    const handleChangeAddress = (event) => {
        const addressId = event.target.value
        setSelectedAddress(addressId)
        setOrder({
            ...order,
            addressId
        })
    }

    const handleChangePickup = () => {
        const _pickup = !order.pickup
        if (_pickup) {
            //si seteo en true el retiro en local, modifico la direccion 
            setOrder({ ...order, pickup: _pickup, addressId: '', address: 'Retira en local', position: [] })
        } else {
            setOrder({ ...order, pickup: _pickup })
        }
    }

    const handleSendOrder = () => {
        setDisabled(true)
        sendOrder().catch(() => {
            setDisabled(false)
        })
    }

    const calculateMax = () => {
        const { amount = 0, usedPoints = 0 } = order
        let max = amount + usedPoints
        if (max > clientPoints) {
            return clientPoints
        }
        return max
    }

    const loginRequired = () => {
        if (!user)
            return (
                <Grid item xs={12}>
                    <Typography align='center' style={{ fontSize: 20 }}>Debes
                        <Button variant="text" onClick={loginWithGoogle} style={{ textTransform: 'none', padding: '0 5 1 5' }}>
                            <Typography color="primary" style={{ fontSize: 20 }}>iniciar sesión</Typography>
                        </Button>
                        para poder concretar la compra.
                    </Typography>
                </Grid>
            )
    }

    const handleChangeSchedule = (date) => {
        setOrder({ ...order, date })
    }


    return (
        <Grid container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
        >
            <OrderProductEdit
                itemToEdit={lineToEdit}
                order={order}
                openForm={editLineOpen}
                setOpenForm={setEditLineOpen}
                onConfirm={handleConfirmLineEdit}
            >
                <Grid
                    container
                    direction="column"
                    alignitems="center"
                    justify="center"
                    style={{ padding: '0px 0px 15px 0px' }}
                >
                    <InputNumber
                        label='Cantidad'
                        value={getProductQty(lineToEdit)}
                        setValue={qty => setProductQty(lineToEdit, qty)}
                        enableSm={false}
                        width={'100%'}
                    />
                </Grid>

            </OrderProductEdit>
            <Dialog
                disableBackdropClick={false}
                message={dialog.message}
                fullWidth={true}
                maxWidth={dialog.maxWidth}
                open={dialog.open}
                setOpen={setDialogOpen}
                acceptText={'Si'}
                cancelText={'No'}
                obj={dialog.obj}
                acceptChanges={deleteProduct}
            />
            <Notification autoHideDuration={autoHideDuration} notificationOpen={notificationOpen} handleNotificationClose={() => setNotificationOpen(false)} severity={severity} notificationMessage={notificationMessage} />
            {
                loginRequired()
            }


            <Grid item xs={12}
                className={classes.listContainer}
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={2}
            >
                {
                    order.productsList?.length === 0 ?
                        <Typography align='center' style={{ width: '100%', padding: 50 }}>No has añadido comida aún.</Typography>
                        :
                        <List className={classes.list}>
                            {
                                order.productsList?.map((product, i) =>
                                    <ListItem key={product.id} className={classes.listItem}>
                                        {/* <ListItemAvatar>
                                            <Avatar alt={product.name} src={product.src} />
                                        </ListItemAvatar> */}
                                        <ListItemText
                                            disableTypography={true}
                                            primary={
                                                <Grid container alignItems="center" justify="center" style={{ overflowX: 'hidden' }}>
                                                    <Grid item xs={1} >
                                                        <Typography>
                                                            {product.qty}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5} >
                                                        <Typography>
                                                            {`${product.name} `}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} container alignItems="center" justify="flex-end">
                                                        {`$${product.qty * product.price}`}
                                                    </Grid>
                                                    <Grid item xs={3} container direction="row" justify="flex-end">
                                                        <Grid item xs={6}>
                                                            <IconButton color="primary" aria-label="Editar" onClick={e => handleEditProduct(product)}>
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <IconButton color="secondary" aria-label="Eliminar" onClick={e => handleDeleteProduct(product)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1}>

                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        <SmartNotes smartNotes={product.smartNotes} typographyColor='textSecondary' />
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                )
                            }
                        </List>
                }
            </Grid>
            <Grid item xs={12}
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={2}
            >
                <Grid item xs={12}>
                    <TextField multiline rows={3} variant="outlined" name="notes" id="notes" value={order.notes || ''} onChange={e => setOrder({ ...order, notes: e.target.value })} label="Comentarios para la entrega" size="small" className={classes.input} placeholder='Ejemplo: Casa con rejas verdes' />
                </Grid>
                <Grid item xs={12}>
                    <Typography gutterBottom>Pagar con puntos</Typography>
                    <Slider onChange={changeUsedPoints} onChangeCommitted={changeUsedPointsCommited} defaultValue={order.usedPoints || 0} min={0} max={calculateMax()} valueLabelDisplay="auto" aria-label="Default" />
                </Grid>
                {
                    order.usedPoints > 0 &&
                    <Grid item xs={12}>
                        <Typography>{`Descuento en puntos $${order.usedPoints || 0}`}</Typography>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Typography>{`Envío $${order.pickup ? 0 : (order.deliveryPrice || 0)}`}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>{`Total $${order.amount || 0}`}</Typography>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 15, marginTop: 30 }}>
                    <PaymentMethod paymentMethods={paymentMethods} method={order.paymentMethod} setMethod={(paymentMethod) => setOrder({ ...order, paymentMethod })} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 0, marginTop: 15 }}>
                    <Grid item xs={12}
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item xs={12} md={4} >
                            <FormControl style={{ width: '100%' }} disabled={order.pickup || !user}>
                                <InputLabel id="address-label">Dirección de entrega</InputLabel>
                                <Select
                                    labelId="address-label"
                                    id="address"
                                    value={selectedAddress}
                                    onChange={handleChangeAddress}
                                >
                                    {
                                        addresses.map(el => <MenuItem key={el.id} value={el.id}>{`${el.address} ${el.number || ''}`}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Button variant="outlined" onClick={() => setOpenAddressForm(true)} color="primary" disabled={order.pickup || !user}>Nueva dirección</Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 30, marginTop: 15 }}>
                    <PickupOptions visible={config?.pickupOption} pickup={order.pickup} setPickup={handleChangePickup} disabled={!user} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 0, marginTop: 15 }}>
                    <ScheduleOrder
                        visible={config?.canScheduleOrder || false}
                        date={order.date}
                        setDate={handleChangeSchedule}
                        businessInfo={businessInfo}
                        localOpen={localOpen}
                        isOpenSoon={isOpenSoon}
                    />
                </Grid>
                <Grid item xs={12}
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    {
                        !user ?
                            loginRequired()
                            :
                            (client?.termsConditions) ?
                                <Button onClick={handleSendOrder} color="primary" variant="contained" disabled={disabled} className={classes.input}>Enviar pedido</Button>
                                :
                                <Link to='/termsConditions' className={classes.link}>
                                    <Button color="secondary" variant="outlined" className={classes.input}>Acepte los términos y condiciones para poder realizar su pedido</Button>
                                </Link>
                    }

                </Grid>
            </Grid>

        </Grid>
    )
}

export default ClientOrderForm

import React, { useState } from 'react'
import {
    Grid,
    FormGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    MenuItem,
    Typography
} from '@material-ui/core'
import { useEffect } from 'react'
import moment from 'moment'
import { convertScheduleBusinessDayToDate, getTodayFromBusinessSchedule } from '../../../utils/dateFunctions'

export default function ScheduleOrder({ visible, date, setDate, businessInfo, localOpen, isOpenSoon }) {
    const [scheduled, setScheduled] = useState(false)
    const [hour, serHour] = useState('')
    const [validTimeOptions, setValidTimeOptions] = useState([])


    useEffect(() => {
        const _date = date || new Date()
        serHour(moment(_date).format('HH:mm'))
    }, [date])

    useEffect(() => {
        if (businessInfo) {
            getValidTimeOptions()
        }
    }, [businessInfo, localOpen, isOpenSoon])

    /// Metodo que setea true o false el check de agendar
    const handleChangeScheduled = () => {
        try {
            const _scheduled = !scheduled

            setScheduled(_scheduled)

            if (validTimeOptions?.length > 0 && _scheduled) {
                serHour(validTimeOptions.at(0))
                handleChangeSchedule(validTimeOptions.at(0))
            } else {
                setDate(new Date())
            }
        } catch (error) {
            console.error('handleChangeScheduled', error)
        }
    }

    /// metodo que se ejecuta cuando se cambia la hora en el textfield
    const handleChangeSchedule = (value) => {
        const newHour = value.target.value
        // console.log('handleChangeSchedule', newHour)
        // Obtén la fecha y hora actual
        const now = moment();

        // Obtén la fecha de hoy con la nueva hora
        let scheduledDate = moment(newHour, 'HH:mm');

        // Compara si la nueva hora es menor que la hora actual
        if (scheduledDate.isBefore(now)) {
            // Si es menor, agrega un día a la fecha
            scheduledDate = scheduledDate.add(1, 'day');
        }

        setDate(scheduledDate.toDate())
        serHour(newHour)
    }

    const getValidTimeOptions = () => {
        // console.log('getValidTimeOptions')
        if (!localOpen && !isOpenSoon) {
            return setValidTimeOptions([]);
        }

        const { schedule = [] } = businessInfo;
        const todaySchedule = getTodayFromBusinessSchedule(schedule);
        const today = convertScheduleBusinessDayToDate(todaySchedule);

        const currentTime = moment(); // Hora actual
        let startTime = moment(today.fromDate, 'HH:mm');
        const endTime = moment(today.toDate, 'HH:mm');

        // Si la hora actual es mayor que la hora de inicio, comienza desde el siguiente múltiplo de 15 minutos
        if (currentTime.isAfter(startTime)) {
            const remainder = (currentTime.minute() % 15);
            const minutesToAdd = remainder === 0 ? 15 : 15 - remainder;
            startTime = currentTime.add(minutesToAdd, 'minutes');
        } else {
            // Si la hora actual está antes de la hora de inicio, ajusta startTime al próximo múltiplo de 15 minutos
            const remainder = startTime.minute() % 15;
            if (remainder !== 0) {
                startTime.add(15 - remainder, 'minutes');
            }
        }

        const validTimes = [];
        const interval = 15; // en minutos

        // si el primer intervalo ya esta dentro de los 15 minutos, le agrego 15 para no agregarlo
        // en caso de que sean 20:48, el primer valor que se va a mostrar en el combo va a ser 21:15
        const minutesDifference = startTime.diff(moment(), 'minutes');
        if (minutesDifference < 15) {
            startTime.add(interval, 'minutes');
        }

        // Mientras el tiempo actual más 15 minutos sea antes del tiempo de cierre
        while (startTime.isBefore(endTime) && startTime.isBefore(currentTime.clone().add(15, 'minutes'))) {
            validTimes.push(startTime.format('HH:mm'));
            startTime.add(interval, 'minutes');
        }

        setValidTimeOptions(validTimes);
        if (validTimes.length > 0) {
            serHour(validTimes.at(0))
        }
    };


    if (!visible)
        return <></>

    if (!localOpen && !isOpenSoon) {
        return <Typography style={{ color: '#ff9e1fff' }}>El local se encuentra cerrado el dia de hoy</Typography>
    }

    if (validTimeOptions?.length === 0)
        return <></>

    return (
        <Grid
            container
            direction="row"
            justifycontent="flex-start"
            alignItems="flex-end"
        >
            <Grid item xs={12}>
                {
                    (!localOpen && isOpenSoon) && <Typography style={{ color: '#ff9e1fff' }}>{`Abrimos a las ${validTimeOptions.at(0)}`}</Typography>
                }
            </Grid>
            <Grid item>
                <FormGroup>
                    <FormControlLabel
                        label="Agendar pedido"
                        control={
                            <Checkbox
                                checked={scheduled}
                                onChange={handleChangeScheduled}
                                name="schedule"
                                color="primary"
                            />
                        }
                    />
                </FormGroup>
            </Grid>

            <Grid item>
                <TextField
                    id="time"
                    label="Hora"
                    type="time"
                    margin="dense"
                    disabled={!scheduled}
                    value={scheduled ? hour : moment(date || new Date()).format('HH:mm')}
                    onChange={handleChangeSchedule}
                    onClick={getValidTimeOptions}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 900, // 15 minutos en segundos
                    }}
                    select
                >
                    {
                        scheduled ?
                            validTimeOptions.map((time) => (
                                <MenuItem key={time} value={time}>
                                    {time}
                                </MenuItem>
                            ))
                            :
                            <MenuItem value={moment(date || new Date()).format('HH:mm')}>{moment(date || new Date()).format('HH:mm')}</MenuItem>
                    }
                </TextField>
            </Grid>
        </Grid>
    )
}

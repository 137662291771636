import React, { createContext, useEffect, useRef, useState } from 'react'
//import firebase from 'firebase/app'
import firebase from 'firebase/compat/app'
import { isUserAdmin, isUserDelivery, isUserChef, isUserDev, getUsers } from '../firebase/obj/user'
import { getClientsRealTime } from '../firebase/obj/client'
import { getConfig, getConfigRealTime } from '../firebase/obj/config'
import { getRegions } from '../firebase/obj/region'
import { checkTermsAndConditions } from '../utils'
import { getPaymentMethods } from '../firebase/obj/paymentMethods'
import { getBusinessInfo } from '../firebase/obj/businessInfo'
import { getOrderStates, sortSates } from '../firebase/obj/orderState'
import { getProductsCategory, getProductsRealTime } from '../firebase/obj/product'
import { getStaff } from '../firebase/obj/staff'
import { getActiveGroups, getGroupsRealTime } from '../firebase/obj/productGroup'
import { beepersRealTime } from '../firebase/obj/beepers'
import { getModules } from '../firebase/obj/modules'
//import { FlashOnTwoTone } from '@material-ui/icons'
export const Context = createContext()


const Provider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(() => {
    //console.log(window.sessionStorage.getItem('user'))
    return window.sessionStorage.getItem('user')
  })
  const [modules, setModules] = useState({})
  const [user, setUser] = useState(null)
  const [isAdmin, setAdmin] = useState(false)
  const [isDev, setDev] = useState(false)
  const [isDelivery, setDelivery] = useState(false)
  const [isChef, setChef] = useState(false)
  const [clients, setClients] = useState([])
  const [config, setConfig] = useState({})
  const [cart, setCart] = useState({ productsList: [], totalQty: 0, pickup: false })
  const [paymentMethods, setPaymentMethods] = useState([])
  const [businessInfo, setBusinessInfo] = useState({})
  const [regions, setRegions] = useState([])
  const [orderStates, setOrderStates] = useState([])
  const [products, setProducts] = useState([])
  const [productsFiltered, setProductsFiltered] = useState([])
  const [staffs, setStaffs] = useState([])
  const [productGroups, setPorductGroups] = useState([])
  const [productsCategories, setProductsCategory] = useState([])
  const [beepers, setBeepers] = useState([])
  const [search, setSearch] = useState('')
  const layoutContainerRef = useRef()

  useEffect(() => {
    if (user) {
      loadPaymentMethods()
      fetchRegions()
      fetchOrderStates()
    }
  }, [user])

  useEffect(() => {
    if (search && search.length > 0 && products.length > 0) {
      const searchLower = search.toLowerCase()
      const filtered = products.filter(p => p.name.toLowerCase().includes(searchLower))
      // console.log('filtered', filtered)
      setProductsFiltered(filtered)
    } else {
      setProductsFiltered(products)
    }
  }, [products, search])

  useEffect(() => {
    function fetchData() {
      getConfigRealTime(setConfig)
      getBusinessInfo().then(info => setBusinessInfo(info))
      getProductsRealTime(setProducts)
    }
    return fetchData()
  }, [])

  useEffect(() => {
    const loadData = () => {
      let cleanupFunction = () => { }
      if (isAdmin) {
        const _setState = data => {
          // console.log('getClientsRealTime from context')
          setClients(data)
        }
        getModules().then(mods => setModules(mods))
        // getStaff().then(staffs => setStaffs(staffs))
        getUsers().then(staffs => setStaffs(staffs))
        getProductsCategory().then(categories => setProductsCategory(categories))
        //getProducts().then(products => setProducts(products))
        const _getGroupsRealTime = getGroupsRealTime(setPorductGroups)
        const _getClientsRealTime = getClientsRealTime(_setState)
        const _beepersRealTime = beepersRealTime(setBeepers)
        cleanupFunction = () => {
          if (_getGroupsRealTime) {
            _getGroupsRealTime()
          }
          if (_getClientsRealTime) {
            _getClientsRealTime()
          }
          if (_beepersRealTime) {
            _beepersRealTime()
          }
        };
      } else {
        getActiveGroups().then(groups => setPorductGroups(groups))
      }
      return cleanupFunction
    }
    return loadData()
  }, [isAdmin])

  const fetchRegions = () => getRegions().then(regions => setRegions(regions))

  const fetchOrderStates = () => getOrderStates().then(orderStates => {
    let states = orderStates.sort(sortSates)
    if (isDelivery) {
      states = states.filter(s => s.value > 40)
    }
    setOrderStates(states)
  })

  const loadPaymentMethods = () =>
    getPaymentMethods().then(data => {
      // console.log('loadPaymentMethods', data)
      setPaymentMethods(data)
    })


  const addToCart = (item) => {
    let newItem = true
    let totalQty = 0
    const productsList = cart.productsList?.map(prod => {
      if (item.id === prod.id) {
        prod.qty = item.qty
        newItem = false
      }
      totalQty += prod.qty
      return prod
    })

    if (newItem) {
      totalQty += item.qty
      productsList?.push(item)
    }

    setCart({ ...cart, productsList, totalQty })
  }

  const getPaymentMethodName = ({ order, previousText = 'Método de pago: ' }) => {
    // console.log('getPaymentMethodName', order, paymentMethods)
    if (!paymentMethods || !order.paymentMethod)
      return null

    const name = paymentMethods.find(p => p.method === order.paymentMethod)?.name
    return previousText + name
  }

  const value = {
    layoutContainerRef,
    modules,
    isAuth,
    isDev,
    user,
    isAdmin,
    isDelivery,
    isChef,
    clients,
    config,
    cart,
    paymentMethods,
    businessInfo,
    regions,
    orderStates,
    products,
    productsFiltered,
    productsCategories,
    staffs,
    productGroups,
    beepers,
    search,
    setSearch,
    addToCart: addToCart,
    setCart,
    getPaymentMethodName,
    online: navigator.onLine,
    setUser,
    activateAuth: firebase.auth().onAuthStateChanged(user => {
      // console.log('onAuthStateChanged', user)
      setUser(user)
      if (user != null) {
        //console.log('user', user.displayName)
        setIsAuth(true)
        isUserAdmin(user).then(res => setAdmin(res)).catch(err => console.warn(err))
        isUserDev(user).then(res => setDev(res)).catch(err => console.warn(err))
        isUserDelivery(user).then(res => setDelivery(res)).catch(err => console.warn(err))
        isUserChef(user).then(res => setChef(res)).catch(err => console.warn(err))
        setTimeout(() => checkTermsAndConditions(user), 3000)
        window.sessionStorage.setItem('user', user.displayName)
      } else {
        //console.log('user', user)
        setIsAuth(false)
        setAdmin(false)
        setDelivery(false)
        setChef(false)
        window.sessionStorage.setItem('user', null)
      }
    }),
  }

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

const context = {
  Provider,
  Consumer: Context.Consumer
}

export default context
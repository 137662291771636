import React, { Component } from 'react'
import { getBusinessInfo, updateBusinessInfo } from '../firebase/obj/businessInfo'
import BusinessInfoForm from '../components/businessInfoForm'
import BusinessInfo from '../components/businessInfo'
import { CircularProgress } from '@material-ui/core'

export default class BusinessInfoWithData extends Component {
    constructor() {
        super()
        this.state = {
            businessInfo: null
        }
        this.setBusinessInfo = this.setBusinessInfo.bind(this)
        this.handleBusinessInfo = this.handleBusinessInfo.bind(this)
    }

    setBusinessInfo(businessInfo) {
        this.setState({ businessInfo })
    }

    handleBusinessInfo(e) {
        return updateBusinessInfo(this.state.businessInfo)
    }

    loadBusinessInfo() {
        const _this = this
        getBusinessInfo().then(businessInfo => {
            _this.setState({
                businessInfo
            })
        }).catch(_this.setState({ businessInfo: null }))
    }

    componentDidMount() {
        this.loadBusinessInfo()
    }


    render() {
        return (
            <React.Fragment>
                {
                    this.state.businessInfo ?
                        this.props.public ?
                            <BusinessInfo businessInfo={this.state.businessInfo} />
                            :
                            <BusinessInfoForm businessInfo={this.state.businessInfo} updateInfo={this.handleBusinessInfo} setInfo={this.setBusinessInfo} />
                        :
                        <CircularProgress style={{ color: '#000000' }} />
                }
            </React.Fragment>
        )
    }
}